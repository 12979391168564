import { combineReducers } from 'redux'
import { reducer as authReducer } from './modules/auth/index'
import { reducer as coreReducer } from './modules/core/index'
import { reducer as promReducer } from './modules/prom/index'
import { reducer as pathologyReducer } from './modules/pathology/index'
import { reducer as userReducer } from './modules/user/index'
import { reducer as countryReducer } from './modules/country/index'
import { reducer as patientReducer } from './modules/patient/index'
import { reducer as circleReducer } from './modules/circle/index'
import { reducer as serviceProviderReducer } from './modules/serviceProvider/index'
import { reducer as statesReducer } from './modules/state/index'
import { reducer as priceReducer } from './modules/prices/index'
import { reducer as subscriptionReducer } from './modules/subscriptions/index'
import { reducer as protocolReducer } from './modules/observationalProtocol/index'
import { reducer as caseReducer } from './modules/cases/index'
import { reducer as taskReducer } from './modules/task/index'
import { reducer as dashboardReducer } from './modules/dashboard'
import { reducer as languageReducer } from './modules/language'
import { reducer as paymentReducer } from './modules/payment'
import { reducer as reportReducer } from './modules/report'
import { reducer as globalSearchReducer } from './modules/globalSearch'
import { reducer as unitReducer } from './modules/units'
import { reducer as chartReducer } from './modules/chart'
import { reducer as reportBuilderReducer } from './modules/reportBuilder'
import { reducer as termsReducer } from './modules/terms'
import { reducer as treatmentReducer } from './modules/treatment/index'
import { reducer as jurisdictionReducer } from './modules/jurisdiction/index'
import { reducer as sponsorReducer } from './modules/sponsor/index'
import { reducer as auditLogReducer } from './modules/auditLog/index'

import { reducer as form } from 'redux-form'

export default combineReducers({
	auth: authReducer,
	form: form,
	core: coreReducer,
	prom: promReducer,
	pathology: pathologyReducer,
	user: userReducer,
	patient: patientReducer,
	country: countryReducer,
	circle: circleReducer,
	serviceProvider: serviceProviderReducer,
	states: statesReducer,
	price: priceReducer,
	subscription: subscriptionReducer,
	protocol: protocolReducer,
	case: caseReducer,
	task: taskReducer,
	dashboard: dashboardReducer,
	language: languageReducer,
	payment: paymentReducer,
	report: reportReducer,
	search: globalSearchReducer,
	unit: unitReducer,
	chart: chartReducer,
	reportBuilder: reportBuilderReducer,
	terms: termsReducer,
	treatment: treatmentReducer,
	jurisdiction: jurisdictionReducer,
	sponsor: sponsorReducer
})

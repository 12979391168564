import { authenticatedFetch } from './base'

export const addAuditLogEntry = (createLogEntryModel) => {
	return authenticatedFetch(`/api/auditLog`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(createLogEntryModel)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}


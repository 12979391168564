import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { useDispatch } from 'react-redux'
import { addAuditLogEntry } from '../../redux/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { getUser } from '../../utilities/userUtilities'

const MemberCell =  ({ member, addAuditLogEntry }) => {
	const dispatch = useDispatch()
	const onCopy = () => {
		let copyText = document.getElementById(member.email)
		try {
			copyText.select()
			document.execCommand('copy')
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.general.copiedToClipboardText',
					isSuccess: true
				}
			})
		} catch (error) {}
	}

	return (
		<Grid data-testid="member-cell" container direction="column" alignContent="flex-start" alignItems="flex-start">
			<Grid item>
				<Typography
					data-testid="member-cell-name"
					variant="subtitle1"
					style={{
						wordBreak: 'break-word',
						maxWidth: '300px',
						textAlign: 'left'
					}}
				>
					{member && member.memberName}
				</Typography>
			</Grid>
			<Grid item>
				<Grid container
					onCopy={() => 
						{	
							var user = getUser();
							addAuditLogEntry({
								url: window.location.href,
								eventType: 2, 
								actionType: 6, //copy
								authorizedUserId: user.id
							});
						}
					}
				>
					<Grid item>
						<Typography
							data-testid="member-cell-email"
							variant="body2"
							style={{
								wordBreak: 'break-all',
								maxWidth: '300px',
								textAlign: 'left'
							}}
						>
							{member && member.email}{' '}
						</Typography>
					</Grid>
					<Grid item style={{ paddingLeft: '5px', marginTop: '3px' }} >
						<input
							data-testid="member-cell-input-email"
							type="text"
							id={member.email}
							name="email"
							value={member.email}
							style={{ position: 'absolute', left: '-9999px' }}
						/>
						<FileCopyOutlined data-testid="member-cell-button-oncopy" style={{ color: 'grey', cursor: 'pointer' }} onClick={onCopy} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	addAuditLogEntry: (entry) => dispatch(addAuditLogEntry(entry)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl)

export default enhance(MemberCell)
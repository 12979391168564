import * as auditLogs from '../../../apis/auditLogs'
import history from '../../../utilities/history'


const ADD_AUDIT_LOG_REQUESTED = 'ADD_AUDIT_LOG_REQUESTED'
const ADD_AUDIT_LOG_COMPLETE = 'ADD_AUDIT_LOG_COMPLETE'

export const addAuditLogEntry = (entry) => (dispatch) => {
	dispatch({ type: ADD_AUDIT_LOG_REQUESTED, data: {} })

	return auditLogs.addAuditLogEntry(entry).then((response) => {
			return dispatch({ type: ADD_AUDIT_LOG_COMPLETE })
	})
}

